import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import IndexSectionHeaders1 from "../components/headers/IndexSectionHeaders1";
import IndexSectionTestimonials4 from "../components/testimonials/IndexSectionTestimonials4";
import IndexSectionBrands3 from "../components/brands/IndexSectionBrands3";
import IndexSectionFooters2 from "../components/footers/IndexSectionFooters2";

const meta = {
  title: "Ossium Group",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionHeaders1 />
      {/* <IndexSectionTestimonials4 /> */}
      {/* <IndexSectionBrands3 /> */}
      <IndexSectionFooters2 />
    </React.Fragment>
  );
}
