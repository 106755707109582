import React from "react";

export default function IndexSectionFooters2() {
  return (
    <React.Fragment>
      <>
        <section className="pt-12 pt-lg-24 ">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-lg-0 text-center">
                <p className="text-dark">
                  Copyright © 2024 Ossium, LLC. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
