import React from "react";

export default function IndexSectionHeaders1() {
  return (
    <React.Fragment>
      <>
        <section className="pb-12 pb-lg-24 bg-white">
          <div className="container">
            <div className="mw-lg-3xl mt-20 mb-20 mx-auto text-center">
              {" "}
              <img
                className="img-fluid"
                src="ossium-assets/logo-type.svg"
                alt=""
              />
            </div>
          </div>

          <div className="container">
            <div className="mw-lg-3xl mx-auto text-center">
              <h3 className="mb-4">We don't just build technology.</h3>
              <h1 className="mb-4">We deliver solutions.</h1>
              <p className="lead mw-xl mx-auto mb-5">
                Ossium is a collective of systems thinkers, developers,
                designers, and product enthusiasts.
              </p>
              <p className="lead mw-xl mx-auto mb-5">
                We are intentional with our process and thoughtful with our
                work. Get in touch and see if we might be the right fit for your
                project.
              </p>
              <a
                className="ossium-link btn"
                style={{ fontSize: "1.5rem" }}
                href="mailto:info@ossium.co"
              >
                Contact Us
              </a>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
